import { Injectable } from '@angular/core';
import {
  Logger,
  LOG_SOURCE,
  getDaVinciAgentConfig
} from '@amc-technology/davinci-api';
import { ConfigurationService } from './configuration.service';
import {
  ILoggerConfiguration,
  defaultConsoleLoggerConfiguration,
  defaultLoggerConfiguration
} from '@amc-technology/davinci-api/dist/models/LoggerConfiguration';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private _logger?: Logger;

  get logger() {
    if (!this._logger) {
      throw new Error('Logger not initialized.');
    }

    return this._logger;
  }

  constructor(private configService: ConfigurationService) {}
  async initialize() {
    try {
      // Get raw config. Framework does not perform data validation intentionally
      const rawConfig = await getDaVinciAgentConfig();
      // Perform data validation, revert to defaults if configs are invalid
      // or undefined
      const loggerConfig: ILoggerConfiguration = {
        'Log Level': parseInt(
          rawConfig?.variables?.['Log Level']?.toString() ||
            defaultLoggerConfiguration['Log Level'].toString(),
          10
        ),
        'Logger Type':
          rawConfig?.variables?.['Logger Type']?.toString() ||
          defaultLoggerConfiguration['Logger Type'],
        'Premise Logger URL':
          rawConfig?.variables?.['Premise Logger URL']?.toString() ||
          defaultLoggerConfiguration['Premise Logger URL'],
        'Console Logger': {
          'Max Length': parseInt(
            rawConfig?.['Console Logger']?.variables?.[
              'Max Length'
            ]?.toString() ||
              defaultLoggerConfiguration['Console Logger']['Max Length'],
            10
          )
        }
      };
      this._logger = new Logger(
        LOG_SOURCE.AmazonConnect,
        false,
        this.configService.config.loggerApiUrl
      );
      this._logger.setConfiguration(loggerConfig);
    } catch (err) {
      (this._logger = new Logger(
        LOG_SOURCE.AmazonConnect,
        false,
        this.configService.config.loggerApiUrl
      )).logCritical('loggerService.initialize(): Error creating logger!');
    }
  }
}
