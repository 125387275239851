import {
  CHANNEL_TYPES,
  IInteraction,
  INTERACTION_DIRECTION_TYPES,
  INTERACTION_STATES,
  RecordItem
} from '@amc-technology/davinci-api';
import { Injectable } from '@angular/core';
import { ActionsObservable, combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ChannelApiService } from '../channel-api.service';
import { LoggerService } from '../logger.service';
import { ACTION_TYPE, DIRECTION, IAction } from './action';

@Injectable({
  providedIn: 'root'
})
export class ConnectEpicsService {
  rootEpic;

  constructor(
    private channelApi: ChannelApiService,
    private loggerService: LoggerService
  ) {
    this.rootEpic = combineEpics(
      this.ringing,
      this.chatSessionCreation,
      this.taskSessionCreation,
      this.answered,
      this.completed,
      this.hold,
      this.resume,
      this.conference
    );
  }

  constructCADObject(rawCADData: any) {
    if (rawCADData) {
      const cadObject = {};

      // Why chck membership twice? Because weird TSLint rules :/
      for (const cad in rawCADData) {
        if (rawCADData.hasOwnProperty(cad)) {
          cadObject[cad] = {
            DevName: '',
            DisplayName: '',
            Value: rawCADData[cad].value
          };
        }
      }

      return cadObject;
    } else {
      return undefined;
    }
  }

  conference = (action$: ActionsObservable<IAction>) =>
    action$.pipe(
      ofType(ACTION_TYPE.conference),
      mergeMap((action) => {
        try {
          this.loggerService.logger.logDebug(
            `Epics Conference:
          uuid=${action.uuid}
          type=${action.type}
          number=${action.number}
          direction=${action.direction}`
          );
          const direction =
            action.direction === DIRECTION.inbound
              ? INTERACTION_DIRECTION_TYPES.Inbound
              : INTERACTION_DIRECTION_TYPES.Outbound;

          // If CAD exists, extract CAD into an object
          const cadObject = this.constructCADObject(action.CAD);

          let recordItem: RecordItem;

          if (cadObject) {
            recordItem = new RecordItem('', '', '', cadObject);
          } else {
            recordItem = new RecordItem('', '', '');
          }

          recordItem.setPhone('', '', action.number);

          const interaction: IInteraction = {
            interactionId: action.uuid,
            scenarioId: action.uuid,
            state: INTERACTION_STATES.Alerting,
            channelType: CHANNEL_TYPES.Telephony,
            direction: direction,
            details: recordItem
          };

          this.channelApi.setInteraction(interaction);
        } catch (e) {
          this.loggerService.logger.logError(
            `Epics Conferencing:
          uuid=${action.uuid}
          type=${action.type}
          number=${action.number}
          direction=${action.direction}
          error=${JSON.stringify(e)}`
          );
        }
        return of({
          type: ACTION_TYPE.noop,
          uuid: action.uuid
        });
      })
    );

  checkIfEmailInString(text) {
    const re =
      // eslint-disable-next-line max-len
      /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    return re.test(text);
  }

  chatSessionCreation = (action$: ActionsObservable<IAction>) =>
    action$.pipe(
      ofType(ACTION_TYPE.incomingMsg),
      // eslint-disable-next-line max-statements
      mergeMap((action) => {
        const fname = 'ConnectEpicsService.chatSessionCreation()';
        try {
          this.loggerService.logger.logDebug(`${fname}: BEGIN`);

          this.loggerService.logger.logTrace(
            `Epics IncomingMsg:
          uuid=${action.uuid}
          type=${action.type}
          number=${action.number}
          direction=${action.direction}
          CAD=${action.CAD}`
          );
          const direction =
            action.direction === DIRECTION.inbound
              ? INTERACTION_DIRECTION_TYPES.Inbound
              : INTERACTION_DIRECTION_TYPES.Outbound;

          // If CAD exists, extract CAD into an object
          const cadObject = this.constructCADObject(action.CAD);

          let recordItem: RecordItem;

          if (cadObject) {
            this.loggerService.logger.logTrace(
              `${fname}: cadObject is not null.`
            );
            recordItem = new RecordItem('', '', '', cadObject);
          } else {
            this.loggerService.logger.logTrace(`${fname}: cadObject is null`);
            recordItem = new RecordItem('', '', '');
          }

          const emailFieldName =
            this.channelApi.config?.['Chat Fields']?.variables?.['Email Field'];

          const fullNameFieldName =
            this.channelApi.config?.['Chat Fields']?.variables?.[
              'Full Name Field'
            ];

          if (action.number) {
            this.loggerService.logger.logTrace(
              `${fname}: Phone number detected. Applying data as 'Phone' CAD`
            );
            recordItem.setPhone('', '', action.number);
          } else {
            if (
              emailFieldName &&
              this.checkIfEmailInString(action.CAD[emailFieldName]?.value)
            ) {
              this.loggerService.logger.logTrace(
                `${fname}: Email Field is a valid email address. Applying data as 'Email' CAD`
              );
              recordItem.setEmail('', '', action.CAD[emailFieldName].value);
            } else {
              this.loggerService.logger.logTrace(
                `${fname}: No valid email address was found. Deferring to First Name field.`
              );
              if (fullNameFieldName && action.CAD[fullNameFieldName]?.value) {
                this.loggerService.logger.logTrace(
                  `${fname}: Full Name field detected. Applying data as "Full Name" CAD`
                );
                recordItem.setFullName(
                  '',
                  '',
                  action.CAD[fullNameFieldName].value
                );
              } else {
                this.loggerService.logger.logError(
                  `${fname}: Error reading critical CAD data for incoming chat. ` +
                    `Email Field Name=${emailFieldName}, Full Name Field Name=${fullNameFieldName}`
                );
              }
            }
          }

          const interaction: IInteraction = {
            interactionId: action.uuid,
            scenarioId: action.uuid,
            state: INTERACTION_STATES.Alerting,
            channelType: action.number ? CHANNEL_TYPES.SMS : CHANNEL_TYPES.Chat,
            direction: direction,
            details: recordItem
          };

          this.loggerService.logger.logTrace(
            `${fname}: Calling setInteraction()`
          );

          this.channelApi.setInteraction(interaction);
        } catch (e) {
          this.loggerService.logger.logError(
            `Epics IncomingMsg:
          uuid=${action.uuid}
          type=${action.type}
          number=${action.number}
          direction=${action.direction}
          error=${JSON.stringify(e)}`
          );
        }

        this.loggerService.logger.logDebug(`${fname}: END`);
        return of({
          type: ACTION_TYPE.noop,
          uuid: action.uuid
        });
      })
    );

  taskSessionCreation = (action$: ActionsObservable<IAction>) =>
    action$.pipe(
      ofType(ACTION_TYPE.incomingTask),
      mergeMap((action) => {
        try {
          this.loggerService.logger.logDebug(
            `Epics IncomingTask:
          uuid=${action.uuid}
          type=${action.type}
          number=${action.number}
          direction=${action.direction}`
          );
          const direction =
            action.direction === DIRECTION.inbound
              ? INTERACTION_DIRECTION_TYPES.Inbound
              : INTERACTION_DIRECTION_TYPES.Outbound;

          // If CAD exists, extract CAD into an object
          const cadObject = this.constructCADObject(action.CAD);

          let recordItem: RecordItem;

          if (cadObject) {
            recordItem = new RecordItem('', '', '', cadObject);
          } else {
            recordItem = new RecordItem('', '', '');
          }

          if (this.checkIfEmailInString(action.CAD.from.value)) {
            recordItem.setEmail('', '', action.CAD.from.value);
          }
          const interaction: IInteraction = {
            interactionId: action.uuid,
            scenarioId: action.uuid,
            state: INTERACTION_STATES.Alerting,
            channelType: CHANNEL_TYPES.Email,
            direction: direction,
            details: recordItem
          };

          this.channelApi.setInteraction(interaction);
        } catch (e) {
          this.loggerService.logger.logError(
            `Epics IncomingTask:
          uuid=${action.uuid}
          type=${action.type}
          number=${action.number}
          direction=${action.direction}
          error=${JSON.stringify(e)}`
          );
        }
        return of({
          type: ACTION_TYPE.noop,
          uuid: action.uuid
        });
      })
    );

  ringing = (action$: ActionsObservable<IAction>) =>
    action$.pipe(
      ofType(ACTION_TYPE.ringing),
      mergeMap((action) => {
        try {
          this.loggerService.logger.logDebug(
            `Epics Ringing:
          uuid=${action.uuid}
          type=${action.type}
          number=${action.number}
          direction=${action.direction}`
          );
          const direction =
            action.direction === DIRECTION.inbound
              ? INTERACTION_DIRECTION_TYPES.Inbound
              : INTERACTION_DIRECTION_TYPES.Outbound;

          // If CAD exists, extract CAD into an object
          const cadObject = this.constructCADObject(action.CAD);

          let recordItem: RecordItem;

          if (cadObject) {
            recordItem = new RecordItem('', '', '', cadObject);
          } else {
            recordItem = new RecordItem('', '', '');
          }

          recordItem.setPhone('', '', action.number);
          const interaction: IInteraction = {
            interactionId: action.uuid,
            scenarioId: action.uuid,
            state: INTERACTION_STATES.Alerting,
            channelType: CHANNEL_TYPES.Telephony,
            direction: direction,
            details: recordItem
          };

          this.channelApi.setInteraction(interaction);
        } catch (e) {
          this.loggerService.logger.logError(
            `Epics Ringing:
          uuid=${action.uuid}
          type=${action.type}
          number=${action.number}
          direction=${action.direction}
          error=${JSON.stringify(e)}`
          );
        }
        return of({
          type: ACTION_TYPE.noop,
          uuid: action.uuid
        });
      })
    );

  answered = (action$: ActionsObservable<IAction>) =>
    action$.pipe(
      ofType(ACTION_TYPE.answered),
      mergeMap((action) => {
        try {
          this.loggerService.logger.logDebug(
            `Epics Answered:
          uuid=${action.uuid}
          type=${action.type}
          number=${action.number}
          direction=${action.direction}`
          );
          const direction =
            action.direction === DIRECTION.inbound
              ? INTERACTION_DIRECTION_TYPES.Inbound
              : INTERACTION_DIRECTION_TYPES.Outbound;

          // If CAD exists, extract CAD into an object
          const cadObject = this.constructCADObject(action.CAD);

          let recordItem: RecordItem;

          if (cadObject) {
            recordItem = new RecordItem('', '', '', cadObject);
          } else {
            recordItem = new RecordItem('', '', '');
          }

          recordItem.setPhone('', '', action.number);

          const interaction: IInteraction = {
            interactionId: action.uuid,
            scenarioId: action.uuid,
            state: INTERACTION_STATES.Connected,
            channelType: CHANNEL_TYPES.Telephony,
            direction: direction,
            details: recordItem
          };

          this.channelApi.setInteraction(interaction);
        } catch (e) {
          this.loggerService.logger.logError(
            `Epics Answered:
          uuid=${action.uuid}
          type=${action.type}
          number=${action.number}
          direction=${action.direction}
          error=${JSON.stringify(e)}`
          );
        }
        return of({
          type: ACTION_TYPE.noop,
          uuid: action.uuid
        });
      })
    );

  completed = (action$: ActionsObservable<IAction>) =>
    action$.pipe(
      ofType(ACTION_TYPE.completed),
      mergeMap((action) => {
        try {
          this.loggerService.logger.logDebug(
            `Epics Completed:
          uuid=${action.uuid}
          type=${action.type}
          number=${action.number}
          direction=${action.direction}`
          );
          const direction =
            action.direction === DIRECTION.inbound
              ? INTERACTION_DIRECTION_TYPES.Inbound
              : INTERACTION_DIRECTION_TYPES.Outbound;

          // If CAD exists, extract CAD into an object
          const cadObject = this.constructCADObject(action.CAD);

          let recordItem: RecordItem;

          if (cadObject) {
            recordItem = new RecordItem('', '', '', cadObject);
          } else {
            recordItem = new RecordItem('', '', '');
          }

          recordItem.setPhone('', '', action.number);

          const interaction: IInteraction = {
            interactionId: action.uuid,
            scenarioId: action.uuid,
            state: INTERACTION_STATES.Disconnected,
            channelType: CHANNEL_TYPES.Telephony,
            direction: direction,
            details: recordItem
          };

          this.channelApi.setInteraction(interaction);
        } catch (e) {
          this.loggerService.logger.logError(
            `Epics Completed:
          uuid=${action.uuid}
          type=${action.type}
          number=${action.number}
          direction=${action.direction}
          error=${JSON.stringify(e)}`
          );
        }
        return of({
          type: ACTION_TYPE.noop,
          uuid: action.uuid
        });
      })
    );

  hold = (action$: ActionsObservable<IAction>) =>
    action$.pipe(
      ofType(ACTION_TYPE.hold),
      mergeMap((action) => {
        try {
          this.loggerService.logger.logDebug(
            `Epics Hold:
          uuid=${action.uuid}
          type=${action.type}
          number=${action.number}
          direction=${action.direction}`
          );
          const direction =
            action.direction === DIRECTION.inbound
              ? INTERACTION_DIRECTION_TYPES.Inbound
              : INTERACTION_DIRECTION_TYPES.Outbound;

          // If CAD exists, extract CAD into an object
          const cadObject = this.constructCADObject(action.CAD);

          let recordItem: RecordItem;

          if (cadObject) {
            recordItem = new RecordItem('', '', '', cadObject);
          } else {
            recordItem = new RecordItem('', '', '');
          }

          recordItem.setPhone('', '', action.number);

          const interaction: IInteraction = {
            interactionId: action.uuid,
            scenarioId: action.uuid,
            state: INTERACTION_STATES.OnHold,
            channelType: CHANNEL_TYPES.Telephony,
            direction: direction,
            details: recordItem
          };

          this.channelApi.setInteraction(interaction);
        } catch (e) {
          this.loggerService.logger.logError(
            `Epics Hold:
          uuid=${action.uuid}
          type=${action.type}
          number=${action.number}
          direction=${action.direction}
          error=${JSON.stringify(e)}`
          );
        }
        return of({
          type: ACTION_TYPE.noop,
          uuid: action.uuid
        });
      })
    );

  resume = (action$: ActionsObservable<IAction>) =>
    action$.pipe(
      ofType(ACTION_TYPE.resume),
      mergeMap((action) => {
        try {
          this.loggerService.logger.logDebug(
            `Epics Resume:
          uuid=${action.uuid}
          type=${action.type}
          number=${action.number}
          direction=${action.direction}`
          );
          const direction =
            action.direction === DIRECTION.inbound
              ? INTERACTION_DIRECTION_TYPES.Inbound
              : INTERACTION_DIRECTION_TYPES.Outbound;

          // If CAD exists, extract CAD into an object
          const cadObject = this.constructCADObject(action.CAD);

          let recordItem: RecordItem;

          if (cadObject) {
            recordItem = new RecordItem('', '', '', cadObject);
          } else {
            recordItem = new RecordItem('', '', '');
          }

          recordItem.setPhone('', '', action.number);

          const interaction: IInteraction = {
            interactionId: action.uuid,
            scenarioId: action.uuid,
            state: INTERACTION_STATES.Connected,
            channelType: CHANNEL_TYPES.Telephony,
            direction: direction,
            details: recordItem
          };

          this.channelApi.setInteraction(interaction);
        } catch (e) {
          this.loggerService.logger.logError(
            `Epics Resume:
          uuid=${action.uuid}
          type=${action.type}
          number=${action.number}
          direction=${action.direction}
          error=${JSON.stringify(e)}`
          );
        }
        return of({
          type: ACTION_TYPE.noop,
          uuid: action.uuid
        });
      })
    );
}
